<template>
  <div class="d-flex flex-column">
    <div class="owner-detail d-flex justify-content-between">
      <button ref="editButton" class="btn edit-button" @click="$router.push({ name: 'VerifyOwnership' })">
        <i class="fas fa-pencil-alt"></i>
      </button>
    </div>
    <div class="d-grid grid-columns-2 gap-4">
      <div>
        <label for="firstName">
          <span>First name</span>
        </label>
        <span>{{ individual.firstName }}</span>
      </div>
      <div>
        <label for="middleName">
          <span>Middle name</span>
        </label>
        <span>{{ individual.middleName || 'N/A' }}</span>
      </div>
      <div>
        <label for="lastName">
          <span>Last name</span>
        </label>
        <span>{{ individual.lastName }}</span>
      </div>
      <div v-if="(isContact && individual.position) || individual.title">
        <label :for="isContact ? 'position' : 'title'">
          <span>{{ isContact ? 'Position' : 'Title' }}</span>
        </label>
        <span>{{ isContact ? individual.position : 'Mr.' }}</span>
      </div>
      <div>
        <label for="birthDate">
          <span>Date of birth</span>
        </label>
        <span>{{ individual.birthDate || 'N/A' }}</span>
      </div>
      <div>
        <label for="phoneNumber">
          <span>Phone</span>
        </label>
        <span>{{ individual.phoneNumber }}</span>
      </div>
      <div>
        <label for="email">
          <span>Email</span>
        </label>
        <span>{{ individual.email }}</span>
      </div>
      <div>
        <label for="ssn">
          <span>SSN</span>
        </label>
        <span>{{ individual.ssn }}</span>
      </div>
      <div v-if="!isContact && type === 'business'">
        <label for="ownershipPercentage">
          <span>Ownership</span>
        </label>
        <span>{{ individual.ownershipPercentage }}%</span>
      </div>
      <div v-if="!isContact">
        <label for="address">
          <span>Address</span>
        </label>
        <span>{{ formatAddress(individual) }}</span>
      </div>
      <div v-if="!isContact">
        <label for="city">
          <span>City</span>
        </label>
        <span>{{ individual.city }}</span>
      </div>
      <div v-if="!isContact">
        <label for="state">
          <span>State</span>
        </label>
        <span>{{ individual.state }}</span>
      </div>
      <div v-if="!isContact">
        <label for="postalCode">
          <span>Zip code</span>
        </label>
        <span>{{ individual.postalCode }}</span>
      </div>
      <div>
        <label for="country">
          <span>Country</span>
        </label>
        <span>{{ individual.country || 'US' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mustHaveStreetName,
  mustHaveStreetNumber,
  validPhoneFormat,
  validSSN,
  validzipcodeRegex
} from '@/helpers/CustomValidations';
import { IDENTITY_TYPE } from '../helpers/constants';

export default {
  props: {
    individual: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    isContact: {
      type: Boolean,
      default: false
    }
  },
  inject: ['$validator'],
  data() {
    return {
      IDENTITY_TYPE
    };
  },
  created() {
    this.$validator.extend(mustHaveStreetName.name, mustHaveStreetName.obj);
    this.$validator.extend(mustHaveStreetNumber.name, mustHaveStreetNumber.obj);
    this.$validator.extend(validPhoneFormat.name, validPhoneFormat.obj);
  },
  computed: {
    modelValidations() {
      const baseValidations = {
        firstName: { required: true, max: 24 },
        middleName: { max: 24 },
        lastName: { required: true, max: 24 },
        birthDate: { required: true },
        phoneNumber: { required: true, validPhoneFormat: true },
        email: { required: true, email: true, max: 70 },
        ssn: { required: true, regex: validSSN },
      };

      if (this.isContact) {
        baseValidations.position = { required: true, max: 40 };
      } else {
        baseValidations.streetNum = { required: true, max: 9 };
        baseValidations.streetName = { required: true, max: 40 };
        baseValidations.city = { required: true, max: 30 };
        baseValidations.state = { required: true };
        baseValidations.postalCode = { required: true, regex: validzipcodeRegex, max: 30 };

        if (this.type === 'business') {
          baseValidations.ownershipPercentage = { between: [1, 100] };
        }
      }

      return baseValidations;
    }
  },
  methods: {
    formatAddress(individual) {
      if (!individual) return 'N/A';
      const { streetNum, streetName } = individual;
      return streetNum && streetName ? `${streetNum} ${streetName}` : 'N/A';
    },
    async validate() {
      const validations = this.modelValidations;
      let isValid = true;
      const errors = {};

      const validateField = async (field, rules) => {
        try {
          const value = this.individual[field];
          const result = await this.$validator.verify(value, rules, { name: field });
          if (!result.valid) {
            isValid = false;
            errors[field] = result.errors[0];
            console.error(`Validation failed for ${field}:`, result.errors[0]);
          }
        } catch (error) {
          console.error(`Error validating field ${field}:`, error);
          isValid = false;
          errors[field] = 'Validation error occurred';
        }
      };

      try {
        for (const [field, rules] of Object.entries(validations)) {
          await validateField(field, rules);
        }

        if (!isValid) {
          console.error(`${this.isContact ? 'Contact' : 'Individual'} data validation errors:`, errors);
        }

        return { isValid, errors };
      } catch (error) {
        console.error('An error occurred during validation:', error);
        return { isValid: false, errors: { general: 'Validation process failed' } };
      }
    },
    getError(fieldName, fieldLabel) {
      const error = this.$validator.errors.first(fieldName);
      return error ? error.replace(` ${fieldLabel}`, '') : null;
    }
  }
};
</script>
