var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group main-div",class:{
    'input-group-focus': _vm.focused,
    'has-label': _vm.label
  }},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"input-label d-flex",style:(_vm.labelStyle)},[(_vm.required)?_c('i',{staticClass:"required-field float-left"}):_vm._e(),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.label))]),(_vm.tooltip)?_c('div',{staticClass:"position-relative"},[_c('i',{staticClass:"fa fa-question-circle tooltip-icon"}),_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(_vm.tooltip))])]):_vm._e()]):_vm._e()]}),_c('div',{staticClass:"input-wrapper mb-0",class:{
      'input-group': _vm.hasIcon || _vm.$slots.addonRight
    }},[(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',[_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text",class:{
              'error-brdr': _vm.hasError,
              'success-brdr': !_vm.hasError && _vm.touched
            }},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]})],2):_vm._e(),_vm._t("default",function(){return [_c('el-select',_vm._g({directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus),expression:"focus"}],ref:"select",staticClass:"select-primary",class:{
          'error-brdr': _vm.hasValidation && _vm.hasError,
          'success-brdr': _vm.hasValidation && !_vm.hasError && _vm.touched
        },attrs:{"filterable":"","remote":_vm.isRemoteSearch,"remote-method":_vm.handleRemoteSearch,"loading":_vm.loading,"name":_vm.name,"value":_vm.internalValue,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"popper-class":_vm.popperClass,"filter-method":!_vm.isRemoteSearch ? _vm.filterMethod : undefined,"multiple":_vm.multiple,"allow-create":_vm.allowCreate,"collapse-tags":_vm.collapseTags,"value-key":_vm.isRemoteSearch ? 'label' : undefined,"id":_vm.id || 'base-select'},nativeOn:{"keydown":function($event){return _vm.handleKeydown.apply(null, arguments)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.loading)?_c('p',{staticClass:"loading-suggestions"},[_c('i',{staticClass:"el-icon-loading"}),_c('span',[_vm._v("Loading suggestions...")])]):_c('p',{staticClass:"no-suggestions"},[_vm._v("No suggestions")])]},proxy:true}])},_vm.listeners),_vm._l((_vm.currentOptions),function(option,index){return _c('el-option',{key:option.key || option.value + index,staticClass:"select-primary",class:option.class,attrs:{"label":option.label,"value":_vm.isRemoteSearch ? option : option.value,"disabled":option.disabled,"id":option.key}},[(option.class === 'loading-spinner-option')?[_c('div',{staticClass:"loading-spinner-content"},[_c('i',{staticClass:"el-icon-loading"}),_c('span',[_vm._v("Loading suggestions...")])])]:[_vm._v(" "+_vm._s(option.label)+" ")]],2)}),1)]}),(_vm.addonRightIcon || _vm.$slots.addonRight || _vm.hasValidation)?_c('div',[_vm._t("addonRight",function(){return [(_vm.hasValidation && !_vm.hasError && _vm.touched)?_c('span',{staticClass:"success-icon"},[_c('i',{staticClass:"fa fa-check-circle",attrs:{"aria-hidden":"true"}})]):(_vm.hasError)?_c('span',{staticClass:"error-icon"},[_c('i',{staticClass:"fa fa-exclamation-triangle",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.addonRightIcon)?_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text",class:{
              'error-brdr': _vm.hasError,
              'success-brdr': !_vm.hasError && _vm.touched
            }},[_c('i',{class:_vm.addonRightIcon})])]):_vm._e()]})],2):_vm._e()],2),(_vm.hasError || _vm.$slots.error)?_vm._t("error",function(){return [_c('i',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.error))])]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }