<template>
  <div
    class="form-group mb-3"
    :class="{
      'input-group-focus': focused,
      'has-label': label,
      'inline-label': inlineLabel,
      'soft-disable': readOnly,
      'has-validation': hasValidation,
      'has-error': error,
      'has-success': hasValidation && !error && touched && !disabled
    }"
  >
    <div class="d-flex justify-content-between">
      <slot name="label">
        <label v-if="label" class="input-label d-flex" :style="labelStyle" :for="name">
          <span>{{ label }}{{ !required ? ' (optional)' : '' }}</span>
          <i v-if="required" class="required-field float-left" />
        </label>
      </slot>
      <slot name="label-extension"></slot>
    </div>
    <div
      v-if="progress != null"
      :style="{
        flex: 1,
        background: `linear-gradient(to right, var(--theme-primary-color) ${progress}%, lightgrey ${progress}%)`,
        height: '4px',
        margin: '0 0 10px 0'
      }"
    ></div>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon,
        'soft-disable': readOnly,
        'has-validation': hasValidation,
        'has-error': error,
        'has-success': hasValidation && !error && touched && !disabled
      }"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': error,
              'success-brdr': !error && touched && !disabled
            }"
          >
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <vue-numeric
          ref="numericInput"
          separator=","
          class="form-control"
          type="text"
          :style="{ width: inputWidth }"
          :class="{
            'error-brdr': error,
            'success-brdr': hasValidation && !error && touched && !disabled,
            'soft-disable': readOnly,
            ...validationClasses
          }"
          :output-type="outputType"
          :name="name"
          :currency="currency"
          :currency-symbol-position="currencyPosition"
          :precision="precision"
          :minus="minus"
          :value="value"
          :placeholder="placeholder"
          :disabled="disabled || readOnly"
          :thousandSeparator="thousandSeparator"
          :min="min"
          :maxlength="maxlength"
          v-focus="focus"
          v-on="listeners"
          @keydown.native="handleKeydown"
          :id="id || 'base-numeric-input'"
        />
      </slot>
      <span
        class="success-icon"
        v-if="showValidationIcon && hasValidation && !error && touched && !disabled && !readOnly"
      >
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </span>
      <span class="error-icon" v-else-if="showValidationIcon && error && !readOnly">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <slot name="error" v-if="(error || $slots.error) && showErrorMsg && !readOnly">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import VueNumeric from './VueNumeric';

export default {
  components: { VueNumeric },
  inheritAttrs: false,
  name: 'BaseNumericInput',
  props: {
    placeholder: String,
    labelStyle: String,
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: '' },
    value: { type: [String, Number] },
    onKeyPress: { type: Function },
    addonLeftIcon: { type: String, description: 'Input icon on the left' },
    hasValidation: { type: Boolean, default: true },
    currency: { type: String, default: '$' },
    currencyPosition: { type: String, default: 'prefix' },
    disabled: { type: Boolean, default: false },
    name: { type: String },
    precision: { type: Number, default: 2 },
    thousandSeparator: { type: String },
    showErrorMsg: { type: Boolean, default: true },
    min: Number,
    minus: { type: Boolean, default: false },
    progress: { type: Number, default: undefined },
    outputType: { type: String, default: 'Number' },
    inlineLabel: { type: Boolean, default: false },
    inputWidth: { type: String },
    maxlength: Number,
    readOnly: { type: Boolean, default: false },
    showValidationIcon: { type: Boolean, default: true },
    validationClasses: { type: Object, default: () => ({}) },
    id: { type: String }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false
    };
  },
  computed: {
    hasIcon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt);
    },
    onFocus() {
      this.focused = true;
      this.$emit('focus');
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    },
    reset() {
      this.touched = false;
      this.$refs.numericInput.reset();
    },
    setFocus() {
      this.$refs.numericInput.$el.focus();
    },
    handleKeydown(event) {
      if (event.key === 'Enter') {
        this.$emit('input', this.value);
        this.onBlur();
        this.$emit('switch-focus');
        event.preventDefault();
        return;
      }

      if (this.onKeyPress) {
        this.onKeyPress(event);
      }
    }
  },
  directives: {
    focus: {
      inserted(el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.focus();
        });
      }
    }
  }
};
</script>

<style scoped>
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}

.tooltip-icon {
  margin-left: 5px;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  width: 350px;
  background-color: #322f35;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  /* transform: translateX(-50%); */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-icon:hover + .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.form-control {
  height: 40px !important;
  max-height: 40px !important;
}
</style>