import { scrollToElement } from '../plugins/perfect-scrollbar';

/**
 * FormControlMixin - Unified form control handling including validation, focus management,
 * and keyboard navigation.
 *
 * Usage:
 * 1. Include the mixin
 * 2. Use ref attributes on form elements (format: input-fieldname)
 * 3. Ensure $validator is injected if validation is needed
 */
export const formControlMixin = {
  data() {
    return {
      focusHandlersSetup: false,
      observer: null,
      lastFocusedElement: null,
      userInteracted: false,
      shouldAutoFocus: true
    };
  },

  methods: {
    // === Focus and Navigation ===

    handleFocus(event) {
      const currentElement = event.target;
      if (!currentElement) return;

      this.userInteracted = true;
      this.lastFocusedElement = currentElement;
      this.scrollToElement(currentElement);
    },

    scrollToElement(element) {
      if (!element) return;

      const targetElement = element.$el
        ? element.$el.querySelector('input, select, textarea') || element.$el
        : element;

      if (targetElement.tagName === 'BUTTON') {
        return;
      }

      // Find scrollable parent
      const getScrollParent = node => {
        if (!node || node === document.body) return null;
        const { overflowY } = window.getComputedStyle(node);
        const isScrollable = ['auto', 'scroll'].includes(overflowY);
        return isScrollable && node.scrollHeight > node.clientHeight
          ? node
          : getScrollParent(node.parentElement);
      };

      const scrollParent = getScrollParent(targetElement);
      const rect = targetElement.getBoundingClientRect();

      if (scrollParent) {
        // If we have a scrollable parent, use its dimensions
        const parentRect = scrollParent.getBoundingClientRect();
        const parentCenter = parentRect.height / 2;
        const elementRelativeCenter = rect.top - parentRect.top + rect.height / 2;
        const distanceFromCenter = Math.abs(parentCenter - elementRelativeCenter);

        if (distanceFromCenter > 100) {
          scrollToElement(targetElement, scrollParent);
        }
      } else {
        // Fallback to window scrolling
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const viewportCenter = viewportHeight / 2;
        const elementCenter = rect.top + rect.height / 2;
        const distanceFromCenter = Math.abs(viewportCenter - elementCenter);

        if (distanceFromCenter > 120) {
          scrollToElement(targetElement);
        }
      }

      this.$nextTick(() => {
        if (targetElement.focus) {
          targetElement.focus();
        }
      });
    },

    // === Validation and Error Handling ===

    async validateForm() {
      if (!this.$validator) return true;

      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.focusFirstErrorField();
        return false;
      }
      return true;
    },

    findFirstErrorField() {
      const firstError = this.errors?.items[0];
      if (!firstError) return null;

      const fieldName = firstError.field;
      const cleanFieldName = fieldName.split('.').pop();
      return this.findRefByFieldName(cleanFieldName);
    },

    focusFirstErrorField() {
      this.$nextTick(() => {
        const errorField = this.findFirstErrorField();
        if (!errorField) return;

        if (errorField.$options?.name === 'BaseSelect') {
          errorField.setFocus?.();
        } else if (errorField.$options?.name === 'BaseDatePicker') {
          errorField.focusDatePickerInput?.();
        } else {
          this.focusElement(errorField);
        }

        this.scrollToElement(errorField);
      });
    },

    findRefByFieldName(fieldName) {
      if (!fieldName) return null;

      const normalizedFieldName = fieldName
        .toLowerCase()
        .replace(/\./g, '-')
        .replace(/_/g, '-');

      // Try exact match first
      let exactRef = this.$refs[`input-${normalizedFieldName}`];
      if (exactRef) return exactRef;

      // If not found and it's a nested field, try with just the last part
      const parts = fieldName.split('.');
      if (parts.length > 1) {
        const lastPart = parts[parts.length - 1].toLowerCase();
        exactRef = this.$refs[`input-${lastPart}`];
        if (exactRef) return exactRef;
      }

      // Try finding by name attribute
      const formContent = this.$refs['form-content'];
      if (formContent) {
        const input = formContent.querySelector(`[name="${fieldName}"]`);
        if (input) return input;
      }

      return null;
    },

    // === Navigation and Focus Control ===

    async focusNext(currentRef, nextRef, force = false) {
      if (this.userInteracted && !force && !this.shouldAutoFocus) return;

      // Validate current field if exists
      const currentInput = this.$refs[currentRef];
      if (currentInput && this.$validator) {
        const inputElement = currentInput.$el?.querySelector('input') || currentInput.$el;
        const inputName = inputElement?.getAttribute('name');

        if (inputName) {
          const isValid = await this.$validator.validate(inputName);
          if (!isValid) return;
        }
      }

      this.$nextTick(() => {
        const refElement = this.$refs[nextRef];
        if (!refElement) return;

        if (Array.isArray(refElement)) {
          this.focusArrayElement(refElement[0]);
          return;
        }

        this.focusElement(refElement);
      });
    },

    focusElement(element) {
      if (!element) return;

      // Handle component types
      if (element.$options) {
        switch (element.$options.name) {
          case 'BaseDatePicker':
          case 'BaseSelect':
          case 'BaseCustomInput':
            element.setFocus?.();
            return;
        }
      }

      const targetElement = element.$el || element;
      const focusableElement = this.findNextFocusableElement(targetElement);
      if (focusableElement) {
        focusableElement.focus();
        this.lastFocusedElement = focusableElement;
      }
    },

    focusArrayElement(element) {
      if (!element) return;

      if (element.$options) {
        switch (element.$options.name) {
          case 'BaseDatePicker':
          case 'BaseSelect':
          case 'BaseCustomInput':
            element.setFocus?.();
            return;
        }
      }

      const targetElement = element.$el || element;
      this.focusElement(targetElement);
    },

    findNextFocusableElement(element) {
      if (!element) return null;

      const isFocusable = el =>
        ['INPUT', 'SELECT', 'BUTTON', 'TEXTAREA'].includes(el.tagName) &&
        !el.disabled &&
        !el.readOnly &&
        !el.classList.contains('is-disabled') &&
        el.tabIndex !== -1;

      if (isFocusable(element)) return element;

      const focusableElements = element.querySelectorAll(
        'input:not([disabled]):not([readonly]), ' +
          'select:not([disabled]):not([readonly]), ' +
          'button:not([disabled]), ' +
          'textarea:not([disabled]):not([readonly])'
      );

      return focusableElements[0] || null;
    },

    // === Error Handling ===

    getError(fieldName) {
      const error = this.errors?.first(fieldName);
      if (!error) return null;

      if (error.includes('validation failed')) {
        if (error.includes('tinType')) return 'Please select a TIN Type';

        const businessMatch = error.match(/identity\.business\.(\w+):/);
        if (businessMatch) {
          return `Please provide a valid ${businessMatch[1]}`;
        }

        return 'Please provide a valid value';
      }

      return error;
    },

    // === Setup and Cleanup ===

    setupFocusHandlers() {
      if (this.focusHandlersSetup || !this.$el) return;

      this.$el.addEventListener('mousedown', () => (this.userInteracted = true), true);
      this.$el.addEventListener('touchstart', () => (this.userInteracted = true), true);

      this.observer = new MutationObserver(() => this.attachEventListeners());
      this.observer.observe(this.$el, {
        childList: true,
        subtree: true
      });

      this.attachEventListeners();
      this.focusHandlersSetup = true;
    },

    attachEventListeners() {
      if (!this.$el?.querySelectorAll) return;

      const inputs = this.$el.querySelectorAll('input, select, textarea, button');
      inputs.forEach(input => {
        if (!input || input.dataset.hasFocusHandlers) return;

        input.addEventListener('focus', this.handleFocus);
        input.addEventListener('keydown', this.handleKeyboardNavigation);
        input.dataset.hasFocusHandlers = 'true';
      });
    },

    handleKeyboardNavigation(event) {
      if (event.key !== 'Enter') return;

      const currentElement = event.target.closest('[ref]');
      const nextElement = currentElement?.nextElementSibling?.querySelector('[ref]');

      if (currentElement && nextElement) {
        event.preventDefault();
        this.focusNext(
          currentElement.getAttribute('ref'),
          nextElement.getAttribute('ref'),
          true
        );
      }
    },

    cleanupFocusHandlers() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }

      if (!this.$el?.querySelectorAll) return;

      const inputs = this.$el.querySelectorAll('input, select, textarea, button');
      inputs.forEach(input => {
        if (!input) return;
        input.removeEventListener('focus', this.handleFocus);
        input.removeEventListener('keydown', this.handleKeyboardNavigation);
        delete input.dataset.hasFocusHandlers;
      });

      this.focusHandlersSetup = false;
      this.lastFocusedElement = null;
      this.userInteracted = false;
    }
  },

  beforeMount() {
    this._readyForSetup = true;
  },

  mounted() {
    if (!this._readyForSetup) {
      return;
    }

    const waitForEl = () => {
      if (!this.$el || this.$el.nodeType === Node.COMMENT_NODE) {
        setTimeout(waitForEl, 50);
        return;
      }
      this.setupFocusHandlers();
    };

    waitForEl();
  },

  beforeDestroy() {
    this.cleanupFocusHandlers();
    this._readyForSetup = false;
  }
};
