<template>
  <section class="main-body-wrapper" id="bank-form" ref="bankForm" @keydown="handleKeyboardNavigation">
    <div class="page-title w-100">
      <h1>Add your business bank account</h1>
      <h2 class="sub-title">
        Payouts you earn will be sent to this account.
        <i>If entering multiple accounts, make sure to check the corresponding labels.</i>
      </h2>
    </div>
    <div class="form-wrapper form-company-info">
      <div class="container-fluid position-relative px-0">
        <form class="d-flex flex-column form-content" ref="form-content" v-scrollbar>
          <div
            v-for="(bankAccount, index) in bankAccounts"
            :key="bankAccount.id"
            :ref="`bankAccountSection-${index}`"
            class="bank-account-section"
            tabindex="0"
          >
            <div
              v-if="bankAccounts.length > 1"
              class="account-header"
              @click="toggleSection(index)"
            >
              <i
                class="fa fa-chevron-down chevron"
                :class="{ 'chevron-rotated': isOpen(index) }"
              ></i>
              <span class="account-label">{{
                bankAccount.accountLabel || `Account ${index + 1}`
              }}</span>
            </div>
            <div
              :class="{
                'account-details': bankAccounts.length > 1,
                'account-details-single': bankAccounts.length === 1,
                open: isOpen(index)
              }"
            >
              <BaseCustomInput
                :ref="`nameOnAccount-${index}`"
                :id="`bank-name-on-account-${index}`"
                :name="`nameOnAccount-${index}`"
                required
                label="Name on Account"
                :error="getError(`nameOnAccount-${index}`)"
                v-model="bankAccount.nameOnAccount"
                v-validate="modelValidations.nameOnAccount"
                tooltip="Enter the name as it appears on the bank account."
                @keydown.enter.prevent="
                  handleEnterKey(`nameOnAccount-${index}`, `accountType-${index}`, $event)
                "
              />
              <BaseSelect
                :ref="`accountType-${index}`"
                :id="`bank-account-type-${index}`"
                :name="`accountType-${index}`"
                required
                label="Account Type"
                :options="accountTypeOptions"
                :error="getError(`accountType-${index}`)"
                v-model="bankAccount.accountType"
                v-validate="modelValidations.accountType"
                tooltip="Select the type of bank account (e.g., Checking, Savings)."
                @switch-focus="
                  handleEnterKey(
                    `accountType-${index}`,
                    `accountRouting-${index}`,
                    $event
                  )
                "
                @keydown.enter.prevent="
                  handleEnterKey(
                    `accountType-${index}`,
                    `accountRouting-${index}`,
                    $event
                  )
                "
              />
              <BaseCustomInput
                :ref="`accountRouting-${index}`"
                :id="`bank-routing-number-${index}`"
                :name="`accountRouting-${index}`"
                label="Routing Number"
                data-vv-as="Routing Number"
                required
                :error="getError(`accountRouting-${index}`)"
                v-maska="'#########'"
                v-model="bankAccount.accountRouting"
                v-validate="modelValidations.accountRouting"
                tooltip="A 9-digit number that identifies your bank. You can find it on your checks or bank statements."
                @keydown.enter.prevent="
                  handleEnterKey(
                    `accountRouting-${index}`,
                    `accountNumber-${index}`,
                    $event
                  )
                "
              />
              <BaseCustomInput
                :ref="`accountNumber-${index}`"
                :id="`bank-account-number-${index}`"
                :name="`accountNumber-${index}`"
                label="Account Number"
                data-vv-as="Account Number"
                required
                :error="getError(`accountNumber-${index}`)"
                v-maska="'###################'"
                v-model="bankAccount.accountNumber"
                v-validate="modelValidations.accountNumber"
                tooltip="Your unique bank account number. You can find it on your checks or bank statements."
                @keydown.enter.prevent="
                  handleEnterKey(
                    `accountNumber-${index}`,
                    `confirmAccountNumber-${index}`,
                    $event
                  )
                "
              />
              <BaseCustomInput
                :id="`bank-confirm-account-number-${index}`"
                :ref="`confirmAccountNumber-${index}`"
                :name="`confirmAccountNumber-${index}`"
                label="Confirm Account Number"
                required
                :error="getError(`confirmAccountNumber-${index}`)"
                v-maska="'###################'"
                v-model="bankAccount.confirmAccountNumber"
                v-validate="{ required: true, confirmed: bankAccount.accountNumber }"
                class="position-relative"
                :class="{ 'mb-0': true }"
                :style="{
                  minHeight: 0,
                  zIndex: index === bankAccounts.length - 1 ? 2 : 'auto'
                }"
                @keydown.enter.prevent="
                  handleEnterKey(
                    `confirmAccountNumber-${index}`,
                    index < bankAccounts.length - 1
                      ? `nameOnAccount-${parseInt(index) + 1}`
                      : 'submitButton',
                    $event
                  )
                "
              />
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-end w-100">
          <button
            ref="submitButton"
            class="btn continue-button"
            :disabled="isSubmitting"
            @click="submitForm"
          >
            {{ isSubmitting ? 'Processing...' : 'Continue' }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { formControlMixin } from '@/mixins/FormControlMixin';
import { v4 as uuid } from 'uuid';
import { mapGetters } from 'vuex';
import { scrollToElement } from '../plugins/perfect-scrollbar';

export default {
  name: 'BankForm',
  $_veeValidate: { validator: 'new' },
  mixins: [formControlMixin],
  data() {
    return {
      accountsLabels: [],
      accountsQuantity: 1,
      bankAccounts: [
        {
          nameOnAccount: '',
          accountType: '',
          accountRouting: '',
          accountNumber: '',
          confirmAccountNumber: ''
        }
      ],
      accountTypeOptions: [{ label: 'Checking', value: 'checking' }],
      modelValidations: {
        accountLabel: { required: true },
        nameOnAccount: { required: true },
        accountType: { required: true },
        accountRouting: {
          required: true,
          min: 9,
          max: 9,
          numeric: true
        },
        accountNumber: {
          required: true,
          min: 5,
          max: 17,
          numeric: true
        },
        confirmAccountNumber: {
          required: true,
          confirmed: 'accountNumber'
        }
      },
      openSections: [],
      isSubmitting: false
    };
  },
  computed: {
    ...mapGetters(['getPayout', 'getSaveAndExit', 'getToken'])
  },
  async created() {
    if (this.getToken) {
      try {
        const response = await this.$axios({
          url: '/onboarding/accounts/identity',
          method: 'GET'
        });
        if (['review', 'approved'].includes(response.data.status)) {
          this.$router
            .replace({ name: 'GetStarted', params: { _normalPush: true } })
            .catch(() => {});
          return;
        }
      } catch (error) {
        console.error('Error fetching identity status:', error);
        // Handle error appropriately
      }
    }
  },
  mounted() {
    const loader = this.$loading.show({
      container: this.$refs.bankForm
    });
    this.$store.commit('MARK_STEP_COMPLETED', 3);
    this.initializeBankAccounts().then(() => {
      loader.hide();
      this.$nextTick(() => {
        if (this.bankAccounts.length > 0) {
          this.openSections = [0];
          this.focusFirstInput();
        }
      });
    });

    // Add focus event listeners to all input elements
    this.$nextTick(() => {
      if (this.$el && typeof this.$el.querySelectorAll === 'function') {
        const inputs = this.$el.querySelectorAll('input, select, textarea, button');
        if (inputs && inputs.length) {
          inputs.forEach(input => {
            input.addEventListener('focus', this.handleFocus);
          });
        }
      } else {
        console.warn(
          'Unable to select elements: this.$el is not available or querySelectorAll is not a function'
        );
      }
    });
  },
  watch: {
    getSaveAndExit(newValue) {
      if (newValue) {
        this.saveAndExit();
      }
    }
  },
  methods: {
    toggleSection(index, forceOpen = false) {
      if (forceOpen) {
        if (!this.openSections.includes(index)) {
          this.openSections.push(index);
          this.$nextTick(() => this.focusFirstInputInSection(index));
        }
      } else {
        const sectionIndex = this.openSections.indexOf(index);
        if (sectionIndex > -1) {
          this.openSections.splice(sectionIndex, 1);
        } else {
          this.openSections.push(index);
          this.$nextTick(() => this.focusFirstInputInSection(index));
        }
      }
    },
    isOpen(index) {
      return this.openSections.includes(index) || this.bankAccounts.length === 1;
    },
    submitForm() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.next().finally(() => {
            this.isSubmitting = false;
          });
          return;
        }
        this.isSubmitting = false;
        this.$toasted.show('There are form errors, please check', {
          type: 'error',
          duration: 3000,
          position: 'top-right'
        });
        // Focus on the first error field
        this.focusFirstError();
      });
    },
    async getProductSettings() {
      try {
        const response = await this.$axios({
          url: `/onboarding/spproducts/${this.$route.params.spProductSlug}/settings`,
          method: 'GET',
          params: { ofQuery: this.$route.query?.of, inviteId: this.$route.query?.invite }
        });
        return response.data;
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          this.$router
            .replace({ name: 'GetStarted', params: { _normalPush: true } })
            .catch(() => {});
        }
      }
    },
    async next() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$toasted.show('There are form errors, please check', {
          type: 'error',
          duration: 3000,
          position: 'top-right'
        });
        return;
      }
      const loader = this.$loading.show({
        container: this.$refs.bankForm,
        isFullPage: false
      });
      const settings = this.getDataToSave();
      try {
        await this.$axios(settings);

        this.$store.commit('MARK_STEP_COMPLETED', 3);
        await this.$router
          .push({ name: 'ConfirmProfile', params: { _normalPush: true } })
          .catch(() => {});
      } catch (e) {
        const errorStatus = e.response.status;
        const errorMessage = e.response.data.message
          ? e.response.data.message
          : 'Server side error, please contact the support team.';
        const logObject = {
          module: 'onboarding',
          error: errorMessage,
          details: {
            page: 'BankForm.next',
            endpoint: '/onboarding/accounts/payout',
            data: settings.data
          }
        };
        console.log(JSON.stringify(logObject));
        this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      } finally {
        loader.hide();
      }
    },
    async saveAndExit() {
      const isValid = await this.$validator.validateAll();
      if (!isValid)
        this.$toasted.show('There are form errors, please check', {
          type: 'error',
          duration: 3000,
          position: 'top-right'
        });
      const settings = this.getDataToSave();
      const { data } = settings;
      if (
        data.nameOnAccount &&
        data.accountType &&
        data.accountRouting &&
        data.accountNumber
      )
        await this.$axios(settings);
      this.$router
        .push({ name: 'ApplicationSaved', params: { _normalPush: true } })
        .catch(() => {});
    },
    getDataToSave() {
      const data = this.bankAccounts.map(bankAccount => ({
        accountLabel: bankAccount.accountLabel,
        nameOnAccount: bankAccount.nameOnAccount,
        accountType: bankAccount.accountType,
        accountRouting: bankAccount.accountRouting,
        accountNumber: bankAccount.accountNumber
      }));

      return {
        url: '/onboarding/accounts/payout',
        method: 'POST',
        data
      };
    },
    getError(field) {
      if (!this.errors) return '';
      const error = this.errors.first(field);
      if (!error) return '';

      const fieldName = field
        .split('-')[0]
        .replace(/([A-Z])/g, ' $1')
        .trim();
      const capitalizedFieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

      if (field.includes('confirmAccountNumber') && error.includes('does not match')) {
        return 'The confirmed account number does not match the account number.';
      }
      if (error.includes('is required')) {
        return `The ${capitalizedFieldName} field is required.`;
      }
      if (field.includes('accountNumber') && error.includes('must be at least')) {
        return `The account number must be between 5 and 17 digits long.`;
      }
      return error.replace(/\d+/, '');
    },
    addBankAccount() {
      const newAccount = {
        id: uuid(),
        nameOnAccount: '',
        accountType: '',
        accountRouting: '',
        accountNumber: '',
        confirmAccountNumber: ''
      };
      this.bankAccounts.push(newAccount);
    },
    handleKeyboardNavigation(event) {
      const { activeElement } = document;
      const currentRef = activeElement.getAttribute('name');
      if (!currentRef) return;

      const [field, indexStr] = currentRef.split('-');
      const currentIndex = parseInt(indexStr, 10);

      if (event.key === 'Tab') {
        const isLastField = field === 'confirmAccountNumber';
        const isFirstField = field === 'nameOnAccount';
        const isForwardTab = !event.shiftKey;
        const isBackwardTab = event.shiftKey;
        const isLastAccount = currentIndex === this.bankAccounts.length - 1;

        if (
          (isLastField && isForwardTab && !isLastAccount) ||
          (isFirstField && isBackwardTab)
        ) {
          event.preventDefault();
          const nextIndex = isForwardTab ? currentIndex + 1 : currentIndex - 1;
          this.navigateBetweenAccounts(nextIndex, isForwardTab);
        }
        return;
      }

      if (
        event.key === 'Enter' ||
        !(event.key === 'ArrowUp' || event.key === 'ArrowDown')
      ) {
        return;
      }

      event.preventDefault();
      const nextIndex = event.key === 'ArrowUp' ? currentIndex - 1 : currentIndex + 1;
      this.navigateBetweenAccounts(nextIndex, true);
    },

    navigateBetweenAccounts(nextIndex, isForward) {
      if (nextIndex >= 0 && nextIndex < this.bankAccounts.length) {
        this.toggleSection(nextIndex, true);
        this.$nextTick(() => {
          const nextRef = isForward
            ? `nameOnAccount-${nextIndex}`
            : `confirmAccountNumber-${nextIndex}`;
          this.focusAndScrollTo(nextRef);
        });
      }
    },

    async handleEnterKey(currentRef, nextRef, event) {
      if (event && event.type === 'keydown' && event.key !== 'Enter') {
        return;
      }

      const isValid = await this.validateInput(currentRef);
      if (!isValid) {
        return;
      }

      const [currentField, currentIndex] = currentRef.split('-');
      const nextField = this.getNextField(currentField);

      if (nextField) {
        this.focusAndScrollTo(`${nextField}-${currentIndex}`);
      } else if (parseInt(currentIndex) < this.bankAccounts.length - 1) {
        const nextIndex = parseInt(currentIndex) + 1;
        this.toggleSection(nextIndex, true);
        this.$nextTick(() => {
          const firstFieldOfNextAccount = `nameOnAccount-${nextIndex}`;
          this.focusAndScrollTo(firstFieldOfNextAccount);
        });
      } else {
        this.focusAndScrollTo('submitButton');
      }
    },

    getNextField(currentField) {
      const fields = [
        'nameOnAccount',
        'accountType',
        'accountRouting',
        'accountNumber',
        'confirmAccountNumber'
      ];
      const currentIndex = fields.indexOf(currentField);
      return fields[currentIndex + 1] || null;
    },

    focusNext(nextRef) {
      this.$nextTick(() => {
        const refElement = this.$refs[nextRef];
        if (!refElement) {
          return;
        }

        if (Array.isArray(refElement)) {
          if (
            refElement[0].$options &&
            refElement[0].$options.name === 'BaseDatePicker'
          ) {
            refElement[0].setFocus();
          } else if (
            refElement[0].$options &&
            refElement[0].$options.name === 'BaseSelect'
          ) {
            refElement[0].setFocus();
          } else {
            const targetElement = refElement[0].$el || refElement[0];
            const nextFocusableElement = this.findNextFocusableElement(targetElement);
            if (nextFocusableElement) {
              nextFocusableElement.focus();
            }
          }
        } else {
          // This is for the submit button
          refElement.focus();
        }
      });
    },

    isFocusable(el) {
      return (
        ['INPUT', 'SELECT', 'BUTTON'].includes(el.tagName) &&
        !el.disabled &&
        !el.classList.contains('is-disabled')
      );
    },

    findNextFocusableElement(element) {
      if (this.isFocusable(element)) {
        return element;
      }
      for (const child of element.children) {
        const focusable = this.findNextFocusableElement(child);
        if (focusable) return focusable;
      }
      return null;
    },

    async initializeBankAccounts() {
      const productSettings = await this.getProductSettings();
      if (!productSettings) {
        return;
      }
      const { labels, quantity } = productSettings;
      this.accountsLabels = [...labels];
      this.accountsQuantity = quantity;
      await this.$store.dispatch('GET_PAYOUT_ACCOUNT');
      if (this.getPayout.length < 1) {
        if (this.$route.params.spProductSlug.includes('-stripe-demo')) {
          this.bankAccounts = Array.from({ length: this.accountsQuantity }, () => ({
            id: uuid(),
            nameOnAccount: `${this.$route.params.spProductSlug}-checking`,
            accountType: 'checking',
            accountRouting: '110000000',
            accountNumber: '000123456789',
            confirmAccountNumber: '000123456789',
            accountLabel: 'label'
          }));
        } else if (this.$route.params.spProductSlug.slice(-5) === '-demo') {
          this.bankAccounts = Array.from({ length: this.accountsQuantity }, () => ({
            id: uuid(),
            nameOnAccount: `${this.$route.params.spProductSlug}-checking`,
            accountType: 'checking',
            accountRouting: '011401533',
            accountNumber: '92293895',
            confirmAccountNumber: '92293895',
            accountLabel: 'label'
          }));
        } else if (this.accountsQuantity > 1) {
          this.bankAccounts = Array.from({ length: this.accountsQuantity }, (v, i) => ({
            id: uuid(),
            nameOnAccount: '',
            accountType: '',
            accountRouting: '',
            accountNumber: '',
            confirmAccountNumber: '',
            accountLabel: this.accountsLabels[i] || ''
          }));
          this.$validator.reset();
        }
        if (this.bankAccounts?.length > 0) {
          this.bankAccounts = this.bankAccounts.map((account, index) =>
            this.accountsLabels[index]
              ? { ...account, accountLabel: this.accountsLabels[index] }
              : account
          );
        }
      } else {
        this.bankAccounts = this.getPayout.map(account => ({
          nameOnAccount: account.nameOnAccount,
          accountType: account.accountType,
          accountRouting: account.accountRouting,
          accountNumber: account.accountNumber,
          accountLabel: account.accountLabel ?? '',
          confirmAccountNumber: account.accountNumber
        }));
      }
    },

    focusFirstInput() {
      this.$nextTick(() => {
        const firstInput = this.$refs[`nameOnAccount-0`];

        if (firstInput && firstInput[0]) {
          const inputElement = firstInput[0].$el.querySelector('input');
          if (inputElement) {
            inputElement.focus();
          } else {
            console.warn('Input element not found within the component');
          }
          return;
        }
        console.warn('Unable to focus: First input element not found');
      });
    },

    focusFirstError() {
      const firstErrorField = this.errors.items[0];
      if (firstErrorField) {
        const [fieldName, index] = firstErrorField.field.split('-');
        this.focusNext(`${fieldName}-${index}`);
      }
    },

    smoothScrollTo(element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    },

    focusFirstInputInSection(index) {
      const firstInputRef = `nameOnAccount-${index}`;
      const firstInput = this.$refs[firstInputRef];
      if (firstInput && firstInput[0]) {
        const inputElement = firstInput[0].$el.querySelector('input');
        if (inputElement) {
          inputElement.focus();
        }
      }
    },

    async validateInput(ref) {
      const input = this.$refs[ref];
      if (input && input[0]) {
        const inputElement = input[0].$el.querySelector('input') || input[0].$el;
        const inputName = inputElement.getAttribute('name');
        if (inputName) {
          return await this.$validator.validate(inputName);
        }
      }
      return true;
    },

    focusAndScrollTo(ref) {
      this.$nextTick(() => {
        this.focusNext(ref);
        const element = this.$refs[ref]?.[0]?.$el;
        if (element) {
          this.scrollToInput(element);
        }
      });
    },

    handleFocus(event) {
      const currentElement = event.target;
      if (currentElement) {
        this.scrollToInput(currentElement);
      }
    },

    scrollToInput(element) {
      scrollToElement(element);
    }
  }
};
</script>

<style lang="scss">
#bank-form {
  .page-title {
    text-align: left;
    max-width: 480px;
    width: 100%;

    .sub-title {
      font-size: 16px;
      color: #666;
      font-weight: normal;
      margin-top: 5px;
      line-height: 1.5;
    }
  }

  .form-wrapper {
    max-width: 480px;
    margin: 0 auto;
  }

  .bank-account-section {
    margin-bottom: 20px;
    border-radius: 4px;
    outline: unset;

    .account-header {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      background-color: #f5f5f5;

      .chevron {
        font-size: 14px;
        margin-right: 10px;
        transition: transform 0.3s ease;

        &.chevron-rotated {
          transform: rotate(180deg);
        }
      }

      .account-label {
        font-weight: 600;
      }
    }

    .account-details {
      padding: 15px 0 15px 15px;
      display: none;

      &.open {
        display: block;
        border-left: 2px solid #f5f5f5;
        margin-left: 15px;
      }
    }

    .account-details-single {
      // margin-top: 56px;
      padding: 0;
    }
  }

  .continue-button {
    background-color: var(--main-primary-color, unset);
    color: var(--titles-color);
    border: 1px solid var(--main-primary-color-lighter, unset);
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 400;
    transition: background-color 0.2s ease;
    width: 100%;

    &:hover {
      background-color: var(--main-primary-color, unset);
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  .form-group {
    margin-bottom: 15px;
  }
  .form-content {
    max-height: calc(100vh - 460px);
  }
  .el-input__inner {
    background-color: transparent;
  }

  // .account-details,
  .account-details-single {
    .has-label:first-child {
      .position-relative {
        position: static !important;
        .tooltip-text {
          left: unset;
        }
      }
    }
  }
}
</style>
