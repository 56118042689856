<template>
  <section v-if="identity.type" class="main-body-wrapper" id="verify-ownership-form" ref="loaderContainer">
    <div class="page-title w-100">
      <div class="d-flex align-items-center">
        <h1>
          {{ pageTitle }}
        </h1>
        <span
          v-if="isPrivateEntity && business.ownershipType"
          class="ownership-type-badge"
        >
          {{ formattedOwnershipType }}
        </span>
      </div>
      <h2 class="sub-title">
        {{ pageSubtitle }}
      </h2>
    </div>
    <div class="form-wrapper form-company-info">
      <div class="container-fluid px-0">
        <form @submit.prevent="next">
          <template v-if="isPrivateEntity">
            <template v-if="isReviewMode">
              <div
                v-for="(owner, index) in owners"
                :ref="index === 0 ? 'firstOwner' : null"
                :key="index"
                class="owner-review"
                tabindex="0"
                @keydown.enter.prevent="editOwner(index)"
                @keydown.space.prevent="editOwner(index)"
                @click="editOwner(index)"
              >
                <div class="owner-info">
                  <div class="owner-percentage">{{ owner.ownershipPercentage }}%</div>
                  <div class="d-flex flex-column">
                    <div class="owner-name">
                      {{ owner.firstName }} {{ owner.lastName }}
                    </div>
                    <div class="owner-label">Owner {{ index + 1 }}</div>
                  </div>
                </div>
                <div v-if="ownerErrors[index]" class="error-icon">
                  <i class="fas fa-exclamation-circle"></i>
                </div>
                <div class="edit-icon">
                  <i class="fas fa-chevron-right"></i>
                </div>
                <div
                  class="remove-icon"
                  @click.stop="removeOwner(index)"
                  @keydown.enter.stop="removeOwner(index)"
                  @keydown.space.prevent.stop="removeOwner(index)"
                  tabindex="-1"
                >
                  <i class="fas fa-trash"></i>
                </div>
              </div>

              <div v-if="totalOwnership > 100" class="mb-3">
                <i class="ownership-msg error-msg">
                  Total ownership percentage cannot exceed 100%.
                </i>
              </div>
              <div v-else-if="totalOwnership < 75" class="mb-3">
                <i class="ownership-msg error-msg">
                  Ownership should total 75% or more. Add another owner to continue.
                </i>
              </div>

              <button
                v-if="business.ownershipType !== OWNERSHIP_TYPES.SOLE_PROP || owners.length === 0"
                @click="addNewOwner"
                @keydown.enter="addNewOwner"
                @keydown.space.prevent="addNewOwner"
                type="button"
                class="btn add-owner-button"
                tabindex="0"
              >
                <i class="fas fa-plus"></i> Add Owner
              </button>
            </template>

            <IndividualSubForm
              v-else-if="editingIndex !== null"
              ref="individualSubForm"
              :ownershipType="business.ownershipType"
              :type="identity.type"
              :person="getFormattedPerson(editingIndex)"
              :index="editingIndex"
              @change="handleOwnerChange"
              @onInputBlur="partialUpdateIdentity()"
              @onSubmit="next"
            />
          </template>

          <ContactForm
            v-else
            ref="contactForm"
            :contact="contacts[0]"
            @onInputBlur="partialUpdateIdentity()"
            @onSubmit="next"
          />

          <button
            type="submit"
            class="btn continue-button"
            :disabled="isSubmitting || (isReviewMode && isPrivateEntity && (totalOwnership > 100 || totalOwnership < 75))"
            tabindex="0"
          >
            {{ isSubmitting ? 'Processing...' : 'Continue' }}
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { debounce, improvedStructuredClone } from '@/helpers/utils';
import { formControlMixin } from '@/mixins/FormControlMixin';
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';
import ContactForm from '../components/ContactForm';
import IndividualSubForm from '../components/IndividualSubForm';
import {
  DB_COMPLETE_DATE_FORMAT,
  IDENTITY_TYPE,
  OWNERSHIP_TYPES,
  PRETTY_DATE_FORMAT
} from '../helpers/constants';

export default {
  name: 'VerifyOwnership',
  components: { IndividualSubForm, ContactForm },
  mixins: [formControlMixin],
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      OWNERSHIP_TYPES,
      identity: {},
      owners: [],
      contacts: [],
      business: { ownershipType: null },
      totalOwnership: 0,
      IDENTITY_TYPE,
      isReviewMode: false,
      editingIndex: null,
      ownerErrors: {},
      isSubmitting: false,
      isIntentionalNavigation: false,
      loader: null,
      isLoading: true,
      spProductSlug: null,
      _clearOwners: false
    };
  },
  computed: {
    ...mapGetters(['getSaveAndExit']),
    isPrivateEntity() {
      return (
        this.identity.type !== IDENTITY_TYPE.GOVERNMENT &&
        this.business.ownershipType !== OWNERSHIP_TYPES.PUBLIC_CORP
      );
    },
    pageTitle() {
      return this.isPrivateEntity ? 'Verify Ownership' : 'Verify Contact Information';
    },
    pageSubtitle() {
      return this.isPrivateEntity
        ? 'We use this information to verify your identity in accordance with bank identity and compliance policies.'
        : 'Please provide contact information for your organization.';
    },
    ownersLength() {
      return this.owners.length;
    },
    modelValidations() {
      const validations = {};
      if (this.isPrivateEntity) {
        validations.owners = {
          length: { min_value: 1 },
          totalOwnership: { min_value: 76, max_value: 100 }
        };
      }
      return validations;
    },
    formattedOwnershipType() {
      return this.business.ownershipType?.replace(/([A-Z])(?=[a-z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim() || '';
    }
  },
  async created() {
    this.partialUpdateIdentity = debounce(this.dispatchPartialUpdate, 1500);
    try {
      const response = await this.$axios({
        url: '/onboarding/accounts/identity',
        method: 'GET'
      });
      this.identity = this.transformIdentityData(response.data.identity);
      this.business = this.identity.business || {};
      this.contacts = this.identity.contacts || [];

      // Handle owners initialization based on business type
      if (this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP) {
        if (this.identity.owners?.length) {
          this.addNewOwner(this.identity.owners, false);
          this.isReviewMode = this.owners.length > 1;
          this.editingIndex = this.owners.length === 1 ? 0 : null;
          if (this.owners.length === 1) {
            this.owners[0].ownershipPercentage = 100;
          }
        } else {
          this.addNewOwner(null);
          this.editingIndex = 0;
          this.isReviewMode = false;
        }
      } else {
        if (this.identity.owners?.length) {
          this.addNewOwner(this.identity.owners, false);
        }
        this.isReviewMode = this.identity.type !== IDENTITY_TYPE.GOVERNMENT;
      }
    } catch (e) {
      console.error('Failed to fetch identity data:', e.message);
    } finally {
      this.isLoading = false;
    }
  },
  async mounted() {
    this.$nextTick(() => {
      // this.setupFocusHandlers();
      if (this.isReviewMode && this.$refs.firstOwner) {
        this.focusNext(null, 'firstOwner');
      }
      if (this.identity && this.owners.length > 0) {
        this.$validator.validateAll();
      }
      this.$store.commit('MARK_STEP_COMPLETED', 2);
      if (this.isLoading) {
        this.loader = this.$loading.show({
          container: this.$refs.loaderContainer
        });
      }
    });
  },
  beforeDestroy() {
    this.partialUpdateIdentity.cancel();
  },
  methods: {
    getError(fieldName) {
      return this.errors?.first ? this.errors.first(fieldName) : '';
    },
    firstErrorByRule(fieldName, ruleName) {
      return this.errors?.firstByRule?.(fieldName, ruleName) || '';
    },
    handleOwnerChange(owner) {
      // Update owner data while preserving both address formats
      const updatedOwner = {
        ...owner,
        title: 'Mr.',
        // Store address fields at root level for backend
        streetNum: owner.address?.streetNum || '',
        streetName: owner.address?.streetName || '',
        city: owner.address?.city || '',
        state: owner.address?.state || '',
        postalCode: owner.address?.postalCode || '',
        country: owner.address?.country || 'US',
        // Keep nested address for frontend components
        address: {
          streetNum: owner.address?.streetNum || '',
          streetName: owner.address?.streetName || '',
          city: owner.address?.city || '',
          state: owner.address?.state || '',
          postalCode: owner.address?.postalCode || '',
          country: owner.address?.country || 'US'
        }
      };

      this.$set(this.owners, this.editingIndex, updatedOwner);
      this.calculateTotalOwnership();
      this.$validator.validateAll();
    },
    addNewOwner(owner, isNew = true) {
      if (this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP && this.owners.length >= 1) {
        this.showToast('A sole proprietorship cannot have more than one owner.', 'error');
        return;
      }

      if (Array.isArray(owner)) {
        owner.forEach(ownerItem => {
          const ownerClone = improvedStructuredClone(ownerItem);
          delete ownerClone._id;
          // Ensure address is properly structured
          ownerClone.address = {
            streetNum: ownerItem.address?.streetNum || ownerItem.streetNum || '',
            streetName: ownerItem.address?.streetName || ownerItem.streetName || '',
            city: ownerItem.address?.city || ownerItem.city || '',
            state: ownerItem.address?.state || ownerItem.state || '',
            postalCode: ownerItem.address?.postalCode || ownerItem.postalCode || '',
            country: ownerItem.address?.country || ownerItem.country || 'US'
          };
          this.owners.push({
            ...ownerClone,
            ownershipPercentage: ownerClone.ownershipPercentage || 0
          });
        });
      } else if (owner === null) {
        this.owners.push({
          ownershipPercentage: 0,
          title: 'Mr.',
          address: {
            streetNum: '',
            streetName: '',
            city: '',
            state: '',
            postalCode: '',
            country: 'US'
          }
        });
      } else {
        const ownerClone = improvedStructuredClone(owner);
        delete ownerClone._id;
        // Ensure address is properly structured
        ownerClone.address = {
          streetNum: owner.address?.streetNum || owner.streetNum || '',
          streetName: owner.address?.streetName || owner.streetName || '',
          city: owner.address?.city || owner.city || '',
          state: owner.address?.state || owner.state || '',
          postalCode: owner.address?.postalCode || owner.postalCode || '',
          country: owner.address?.country || owner.country || 'US'
        };
        this.owners.push({
          ...ownerClone,
          ownershipPercentage: ownerClone.ownershipPercentage || 0
        });
      }

      if (this.owners.length === 1 || isNew) {
        this.editOwner(this.owners.length - 1);
      }
    },
    editOwner(index) {
      this.editingIndex = index;
      this.isReviewMode = false;
      this.ownerErrors[index] = false;

      this.$nextTick(() => {
        this.focusNext(null, 'input-first-name');
      });
    },
    removeOwner(index) {
      // Set flag to indicate owner removal intent
      if (this.owners.length === 1) {
        this._clearOwners = true;
      }

      this.owners.splice(index, 1);
      this.calculateTotalOwnership();

      // Cancel any pending autosave
      this.partialUpdateIdentity.cancel();

      // Immediately persist changes to backend with explicit operation
      this.dispatchPartialUpdate();

      if (this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP) {
        if (this.owners.length === 1) {
          this.owners[0].ownershipPercentage = 100;
          this.editingIndex = 0;
          this.isReviewMode = false;
          this.showToast('You can now edit the remaining owner\'s information.', 'success');
        } else if (this.owners.length === 0) {
          this.addNewOwner(null);
          this.editingIndex = 0;
          this.isReviewMode = false;
        }
      } else {
        this.isReviewMode = this.identity.type !== IDENTITY_TYPE.GOVERNMENT;
      }
    },
    calculateTotalOwnership() {
      this.totalOwnership = this.owners.reduce((total, owner) => total + (owner.ownershipPercentage || 0), 0);
    },
    async dispatchPartialUpdate() {
      if (this.isIntentionalNavigation) {
        return;
      }

      const data = this.getDataToUpdate();
      try {
        await this.$axios({
          url: '/onboarding/accounts/identity/autosave',
          method: 'PUT',
          data
        });
      } catch (e) {
        console.error('Failed to dispatch partial update:', e.message);
      }
    },
    refineAndReturnAddress(obj, type = 'individual') {
      const updatedObj = improvedStructuredClone(obj);

      if (updatedObj.address) {
        const addressLine = updatedObj.address.trim();
        const match = addressLine.match(/^(\d+)\s+(.+)$/);

        updatedObj.address = {
          streetNum: match ? match[1] : '',
          streetName: match ? match[2] : addressLine,
          city: '',
          state: '',
          postalCode: '',
          country: 'US'
        };
      } else {
        updatedObj.address = {
          streetNum: '',
          streetName: '',
          city: '',
          state: '',
          postalCode: '',
          country: 'US'
        };
      }

      return updatedObj;
    },
    getFormattedPerson(index) {
      const owner = this.owners[index];
      if (!owner) return null;

      // Create a new object without _id and handle both address formats
      const { _id, ...formattedOwner } = owner;
      return {
        ...formattedOwner,
        // Keep address as a nested object for the form
        address: {
          streetNum: owner.address?.streetNum || owner.streetNum || '',
          streetName: owner.address?.streetName || owner.streetName || '',
          city: owner.address?.city || owner.city || '',
          state: owner.address?.state || owner.state || '',
          postalCode: owner.address?.postalCode || owner.postalCode || '',
          country: owner.address?.country || owner.country || 'US'
        }
      };
    },
    getDataToUpdate() {
      const data = { identity: {} };

      // Set the identity type
      if (this.identity.type === IDENTITY_TYPE.GOVERNMENT) {
        data.identity.type = IDENTITY_TYPE.GOVERNMENT;
      } else if (this.identity.type === IDENTITY_TYPE.INDIVIDUAL) {
        data.identity.type = IDENTITY_TYPE.INDIVIDUAL;
      }

      // Handle business data if it exists
      if (this.identity.business) {
        const { address = {}, ...businessData } = this.identity.business;
        data.identity.business = {
          ...businessData,
          expectedMonthlyVolume: Number(businessData.expectedMonthlyVolume) || 0,
          businessEstablishedDate: businessData.businessEstablishedDate
            ? this.validateAndFormatDate(businessData.businessEstablishedDate, true)
            : null,
          federalTaxId: businessData.federalTaxId?.replace(/-/g, ''),
          phoneNumber: this.business.phoneNumber,
          address: {
            streetNum: address.streetNum || '',
            streetName: address.streetName || '',
            city: address.city || '',
            state: address.state || '',
            postalCode: address.postalCode || '',
            country: address.country || 'US'
          }
        };
      }

      // Handle owners with operation intent
      if (this.owners?.length || this._clearOwners) {
        // Set operation type
        if (this._clearOwners) {
          data.identity.ownershipOperation = 'CLEAR';
        } else if (this.owners.length > 0) {
          data.identity.ownershipOperation = 'UPDATE';
          data.identity.owners = this.owners.map(owner => {
            const { address = {}, dateStarted, _id, ...ownerData } = owner;
            const formattedOwner = {
              ...ownerData,
              title: 'Mr.',
              birthDate: this.validateAndFormatDate(ownerData.birthDate, true),
              dateStarted: dateStarted
                ? this.validateAndFormatDate(dateStarted, true)
                : undefined,
              ownershipPercentage: Number(ownerData.ownershipPercentage) || 0,
              ssn: ownerData.ssn?.replace(/-/g, '') || null,
              streetNum: address.streetNum || '',
              streetName: address.streetName || '',
              city: address.city || '',
              state: address.state || '',
              postalCode: address.postalCode || '',
              country: address.country || 'US'
            };

            // Ensure position is set for demo accounts
            if (this.spProductSlug?.endsWith('-demo') && !ownerData.position) {
              formattedOwner.position = this.business?.ownershipType === OWNERSHIP_TYPES.SOLE_PROP ? 'SP' : 'OW';
            }

            // Remove undefined fields
            Object.keys(formattedOwner).forEach(key => {
              if (formattedOwner[key] === undefined) {
                delete formattedOwner[key];
              }
            });

            return formattedOwner;
          });
        }
        // Reset the clear flag after use
        this._clearOwners = false;
      } else if (this.$refs.contactForm) {
        const contactData = this.$refs.contactForm.getData();
        const contact = contactData.identity.contacts[0];
        const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;

        data.identity = {
          ...data.identity,
          ...contactData.identity,
          contacts: [{
            firstName: contact.firstName || undefined,
            lastName: contact.lastName || undefined,
            email: contact.email || undefined,
            phoneNumber: contact.phoneNumber || undefined,
            ssn: ssnPattern.test(contact.ssn) ? contact.ssn.replace(/-/g, '') : contact.ssn || undefined,
            birthDate: contact.birthDate ? this.validateAndFormatDate(contact.birthDate, true) : undefined,
            position: contact.position || undefined,
            type: contact.type || undefined,
            processor: contact.processor || undefined
          }]
        };
      }

      return data;
    },
    validateAndFormatDate(dateString, toDB = false) {
      if (!dateString) {
        return null;
      }

      let date = DateTime.fromFormat(dateString, PRETTY_DATE_FORMAT);

      if (!date.isValid) {
        date = DateTime.fromFormat(dateString, DB_COMPLETE_DATE_FORMAT);
      }

      if (!date.isValid) {
        console.error('❌ Invalid date format:', dateString);
        return null;
      }

      return toDB ? date.toFormat(DB_COMPLETE_DATE_FORMAT) : date.toFormat(PRETTY_DATE_FORMAT);
    },
    transformIdentityData(data) {
      if (!data) return {};

      // Transform business data
      const transformedBusiness = data.business && {
        ...data.business,
        expectedMonthlyVolume: Number(data.business.expectedMonthlyVolume || 0),
        businessEstablishedDate: this.validateAndFormatDate(data.business.businessEstablishedDate),
        federalTaxId: data.business.federalTaxId?.replace(/(\d{2})(\d{7})/, '$1-$2'),
        address: {
          streetNum: data.business.address?.streetNum || '',
          streetName: data.business.address?.streetName || '',
          city: data.business.address?.city || '',
          state: data.business.address?.state || '',
          postalCode: data.business.address?.postalCode || '',
          country: data.business.address?.country || 'US'
        }
      };

      // Transform owners data
      const transformedOwners = data.owners?.map(owner => {
        const ownerData = { ...owner };  // Clone the owner object
        delete ownerData.phoneType;      // Remove phoneType field
        return {
          ...ownerData,
          birthDate: this.validateAndFormatDate(owner.birthDate),
          dateStarted: this.validateAndFormatDate(owner.dateStarted),
          ownershipPercentage: Number(owner.ownershipPercentage || 0),
          ssn: owner.ssn?.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3') || null,
          address: {
            streetNum: owner.address?.streetNum || owner.streetNum || '',
            streetName: owner.address?.streetName || owner.streetName || '',
            city: owner.address?.city || owner.city || '',
            state: owner.address?.state || owner.state || '',
            postalCode: owner.address?.postalCode || owner.postalCode || '',
            country: owner.address?.country || owner.country || 'US'
          }
        };
      });

      // Transform contacts data if present
      const transformedContacts = data.contacts?.map(contact => ({
        ...contact,
        birthDate: this.validateAndFormatDate(contact.birthDate),
        ssn: contact.ssn?.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3') || null,
        address: {
          streetNum: contact.streetNum || '',
          streetName: contact.streetName || '',
          city: contact.city || '',
          state: contact.state || '',
          postalCode: contact.postalCode || '',
          country: contact.country || 'US'
        }
      }));

      return {
        ...data,
        business: transformedBusiness,
        owners: transformedOwners,
        contacts: transformedContacts
      };
    },
    validateOwners() {
      if (this.business?.ownershipType === OWNERSHIP_TYPES.SOLE_PROP && !this.isSubmitting) {
        return true;
      }

      let isValid = true;
      this.ownerErrors = {};
      const errorOwners = [];
      const errors = {};

      // Fields that should not trigger validation errors
      const excludedFields = ['dateStarted'];

      this.owners.forEach((owner, index) => {
        let hasError = false;
        errors[index] = [];
        const address = owner.address || {};

        // Required fields validation
        if (!owner.address || !address.streetNum || !address.streetName) {
          hasError = true;
          errors[index].push('Invalid address format');
        }

        ['city', 'state', 'postalCode'].forEach(field => {
          if (!address[field]) {
            hasError = true;
            errors[index].push(`${field} is required`);
          }
        });

        // Length validations
        const maxLengths = {
          firstName: 24,
          middleName: 24,
          lastName: 24,
          phoneNumber: 10,
          email: 70
        };

        Object.entries(maxLengths).forEach(([field, maxLen]) => {
          // Skip validation for excluded fields
          if (excludedFields.includes(field)) return;

          const value = owner[field];
          if (value && value.toString().length > maxLen) {
            hasError = true;
            errors[index].push(`${field} max length exceeded`);
          }
        });

        // Address field length validations
        const addressMaxLengths = {
          streetName: 40,
          streetNum: 9,
          city: 30,
          postalCode: 30
        };

        Object.entries(addressMaxLengths).forEach(([field, maxLen]) => {
          const value = address[field];
          if (value && value.toString().length > maxLen) {
            hasError = true;
            errors[index].push(`address.${field} max length exceeded`);
          }
        });

        // SSN validation
        if (!owner.ssn || !/^\d{3}-\d{2}-\d{4}$/.test(owner.ssn)) {
          hasError = true;
          errors[index].push('Invalid SSN');
        }

        if (!hasError) {
          return;
        }
        this.$set(this.ownerErrors, index, true);
        isValid = false;
        errorOwners.push(index + 1);
      });

      if (!isValid) {
        const errorOwnersText = errorOwners.join(', ');
        this.showToast(`Validation failed for owner(s): ${errorOwnersText}`, 'error');
        console.error('Validation errors:', errors);
      }

      return isValid;
    },
    async validateContact() {
      const { contactForm } = this.$refs;
      const isValid = await contactForm.$validator.validateAll();

      if (!isValid) {
        this.showToast('Please correct the errors in the form.', 'error');
      }

      return isValid;
    },
    showToast(message, type) {
      this.$toasted.show(message, { type, duration: 3000 });
    },
    async next() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      const loader = this.$loading.show({
        container: this.$refs.loaderContainer
      });

      try {
        // Cancel any pending autosave
        this.partialUpdateIdentity.cancel();

        if (this.isPrivateEntity) {
          if (this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP) {
            if (this.owners.length > 1) {
              this.showToast('Please remove additional owners. A sole proprietorship can only have one owner.', 'error');
              this.isSubmitting = false;
              return;
            } else if (this.owners.length !== 1) {
              this.showToast('A sole proprietorship must have one owner.', 'error');
              this.isSubmitting = false;
              return;
            }
            this.owners[0].ownershipPercentage = 100;
          }

          if (this.isReviewMode) {
            if (this.totalOwnership > 100) {
              this.showToast('Total ownership percentage cannot exceed 100%.', 'error');
              this.isSubmitting = false;
              return;
            } else if (this.totalOwnership < 75) {
              this.showToast('Ownership should total 75% or more. Add another owner to continue.', 'error');
              this.isSubmitting = false;
              return;
            }
            const isValid = await this.validateOwners();
            if (!isValid) {
              this.isSubmitting = false;
              return;
            }
          } else {
            // Get the IndividualSubForm component reference
            const individualForm = this.$refs.individualSubForm;
            if (individualForm) {
              const isValid = await individualForm.validateForm();
              if (!isValid) {
                this.isSubmitting = false;
                return;
              }
            }
          }
        } else {
          const { contactForm } = this.$refs;
          if (contactForm) {
            const isValid = await contactForm.validateForm();
            if (!isValid) {
              this.isSubmitting = false;
              return;
            }
          }
        }

        // Submit the form
        const response = await this.$axios({
          url: '/onboarding/accounts/identity',
          method: 'PUT',
          data: this.getDataToUpdate()
        });

        // Set the flag to indicate intentional navigation
        this.isIntentionalNavigation = true;

        if (
          this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP ||
          this.isReviewMode ||
          this.identity.type === IDENTITY_TYPE.GOVERNMENT
        ) {
          await this.$router.push({
            name: 'BankForm',
            query: this.$route.query,
            params: { _normalPush: true }
          }).catch(() => {});
        } else {
          this.isReviewMode = true;
        }
      } catch (e) {
        console.error('Submission failed:', e);
        const errorMessage = e.response?.data?.message || 'Submission failed. Please try again.';
        this.showToast(errorMessage, 'error');
        if (this.business.ownershipType !== OWNERSHIP_TYPES.SOLE_PROP) {
          this.isReviewMode = this.identity.type !== IDENTITY_TYPE.GOVERNMENT;
        }
      } finally {
        this.isSubmitting = false;
        loader.hide();
      }
    },
    saveAndExit() {
      const loader = this.$loading.show({
        container: this.$refs.loaderContainer
      });
      try {
        this.partialUpdateIdentity.cancel();
        this.dispatchPartialUpdate();
        this.$router.push({
          name: 'ApplicationSaved',
          params: { _normalPush: true }
        });
      } finally {
        loader.hide();
      }
    }
  },
  watch: {
    owners: {
      handler() {
        let totalOwnership = 0;
        if (this.owners && this.owners.length) {
          if (this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP && this.owners.length === 1) {
            this.owners[0].ownershipPercentage = 100;
          }
          this.owners.forEach(p => {
            if (p.ownershipPercentage) totalOwnership += Number(p.ownershipPercentage);
          });
        }
        this.totalOwnership = totalOwnership;
      },
      deep: true
    },
    'business.ownershipType': {
      handler(newType) {
        if (newType === OWNERSHIP_TYPES.SOLE_PROP) {
          if (this.owners.length > 1) {
            this.isReviewMode = true;
            this.editingIndex = null;
            this.showToast('A sole proprietorship can only have one owner. Please review and remove additional owners.', 'error');
          } else {
            if (this.owners.length === 1) {
              this.owners[0].ownershipPercentage = 100;
            } else {
              this.addNewOwner(null);
            }
            this.editingIndex = 0;
            this.isReviewMode = false;
          }
        }
      }
    },
    getSaveAndExit(newValue) {
      if (newValue) {
        this.saveAndExit();
      }
    },
    isReviewMode: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            if (this.$refs.firstOwner?.[0]) {
              this.focusNext(null, 'firstOwner');
            }
          });
        }
      }
    },
    isLoading(newVal) {
      if (!newVal && this.loader) {
        this.loader.hide();
      }
    }
  }
};
</script>

<style lang="scss">
#verify-ownership-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-title,
  .form-wrapper {
    max-width: 480px;
    margin: 0 auto;

    &.page-title {
      text-align: left;

      .sub-title {
        font-size: 16px;
        margin-top: 5px;
        line-height: 1.5;
      }
    }
  }

  .el-input__inner,
  .mx-input {
    background-color: transparent !important;
  }

  .add-owner {
    background-color: transparent;
  }

  .ownership-type-badge {
    display: inline-block;
    margin-left: 20px;
    padding: 5px 8px;
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--titles-color);
    background-color: var(--main-primary-color-lighter, unset);
    border-radius: 4px;
    line-height: 14px;
    vertical-align: middle;
  }

  .owner-review {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: #f5f5f5;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 3px rgba(20, 213, 132, 0.25);
    }

    .owner-info {
      display: flex;
      align-items: center;

      .owner-percentage {
        font-size: 1.5rem;
        font-weight: bold;
        margin-right: 1rem;
      }

      .owner-name {
        font-size: 1.2rem;
        margin-right: 0.5rem;
      }

      .owner-label {
        font-size: 0.9rem;
        color: #666666;
      }
    }

    .error-icon {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-45%);
      & > i {
        font-size: 16px;
        color: red;
      }
    }

    .edit-icon {
      & > i {
        color: #333333;
      }
    }
    .remove-icon {
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-45%);
      cursor: pointer;
      outline: none;

      &:focus {
        box-shadow: 0 0 0 3px rgba(20, 213, 132, 0.25);
        padding: 4px 8px;
        margin-right: -8px;
      }

      & > i {
        font-size: 16px;
        color: #333;
        outline: 2px solid transparent;
        outline-offset: 2px;
      }
    }
  }

  .ownership-notice {
    font-size: 0.9rem;
    color: #666666;
    margin-bottom: 1rem;
  }

  .add-owner-button {
    background-color: #000000;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;

    &:focus {
      box-shadow: 0px 1px 1px 4px rgba(20, 213, 132, 0.25) !important;
      background-color: #333333;
    }

    i {
      margin-right: 0.5rem;
    }
  }
}
</style>
