<template>
  <form @submit.prevent="handleSubmit" class="d-flex flex-column">
    <div class="form-content" ref="form-content" v-scrollbar>
      <BaseCustomInput
        ref="input-first-name"
        label="First name"
        data-vv-as="First name"
        required
        name="firstName"
        :error="getError('firstName', 'First name')"
        v-model="formValues.firstName"
        v-validate="modelValidations.firstName"
        @keydown.enter.prevent="focusNext('input-first-name', 'input-middle-name')"
        @input="handleInput"
        @blur="handleBlur"
      />
      <BaseCustomInput
        ref="input-middle-name"
        label="Middle name"
        data-vv-as="Middle name"
        name="middleName"
        :error="getError('middleName', 'Middle name')"
        v-model="formValues.middleName"
        v-validate="modelValidations.middleName"
        @keydown.enter.prevent="focusNext('input-middle-name', 'input-last-name')"
        @input="handleInput"
        @blur="handleBlur"
      />
      <BaseCustomInput
        ref="input-last-name"
        label="Last name"
        data-vv-as="Last name"
        required
        name="lastName"
        :error="getError('lastName', 'Last name')"
        v-model="formValues.lastName"
        v-validate="modelValidations.lastName"
        @keydown.enter.prevent="focusNext('input-last-name', 'input-date-of-birth')"
        @input="handleInput"
        @blur="handleBlur"
      />
      <BaseDatePicker
        ref="input-date-of-birth"
        class="mb-0"
        label="Date of birth"
        data-vv-as="Date of birth"
        value-type="format"
        required
        name="birthDate"
        :format="PRETTY_DATE_FORMAT_DP"
        :not-before="minBirthDate"
        :not-after="maxBirthDate"
        :error="getError('birthDate', 'Date of birth')"
        :showCalendarIcon="false"
        v-model="formValues.birthDate"
        v-validate="modelValidations.birthDate"
        @switch-focus="focusNext('input-date-of-birth', 'input-position')"
        @input="handleInput"
        @blur="handleBlur"
      />
      <BaseSelect
        ref="input-position"
        label="Position"
        data-vv-as="Position"
        required
        hasValidation
        name="position"
        :options="positionOptions"
        :error="getError('position', 'Position')"
        v-model="formValues.position"
        v-validate="modelValidations.position"
        @switch-focus="focusNext('input-position', 'input-ssn')"
        @input="handleInput"
        @blur="handleBlur"
      />
      <BaseCustomInput
        ref="input-ssn"
        label="Social Security Num (SSN)"
        data-vv-as="SSN"
        required
        name="ssn"
        :error="getError('ssn', 'Social Security Num (SSN)')"
        v-maska="'###-##-####'"
        v-model="formValues.ssn"
        v-validate="modelValidations.ssn"
        @keydown.enter.prevent="focusNext('input-ssn', 'input-phone-number-contact')"
        @input="handleInput"
        @blur="handleBlur"
      />
      <BasePhoneInput
        ref="input-phone-number-contact"
        label="Phone"
        data-vv-as="Phone number"
        required
        name="phoneNumber"
        :error="getError('phoneNumber', 'Phone')"
        v-model="formValues.phoneNumber"
        v-validate="modelValidations.phoneNumber"
        @keydown.enter.prevent="focusNext('input-phone-number-contact', 'input-email')"
        @blur="handleBlur"
      />
      <BaseCustomInput
        style="z-index: 2"
        ref="input-email"
        label="Email"
        data-vv-as="Email"
        required
        name="email"
        :error="getError('email', 'Email')"
        v-model="formValues.email"
        v-validate="modelValidations.email"
        @keydown.enter.prevent="submitForm"
        @input="handleInput"
        @blur="handleBlur"
      />
    </div>
  </form>
</template>

<script>
import { validPhoneFormat, validSSN } from '@/helpers/CustomValidations';
import { dateMixin } from '@/mixins/DateMixin';
import { formControlMixin } from '@/mixins/FormControlMixin';
import { DateTime } from 'luxon';
import { PRETTY_DATE_FORMAT_DP } from '../helpers/constants';

export default {
  name: 'ContactForm',
  inject: ['$validator'],
  mixins: [dateMixin, formControlMixin],
  props: ['v', 'contact'],
  data() {
    return {
      formValues: {},
      modelValidations: {
        firstName: { required: true, max: 24 },
        middleName: { max: 24 },
        lastName: { required: true, max: 24 },
        phoneNumber: { required: true, validPhoneFormat: true, max: 10 },
        email: { required: true, email: true, max: 70 },
        ssn: { required: true, regex: validSSN },
        birthDate: { required: true, validDateFormat: true, dateLessThanTomorrow: true },
        position: { required: true }
      },
      PRETTY_DATE_FORMAT_DP,
      debounceTimer: null
    };
  },
  computed: {
    minBirthDate() {
      return DateTime.now().minus({ years: 100 }).toJSDate();
    },
    maxBirthDate() {
      return DateTime.now().minus({ years: 18 }).toJSDate();
    },
    positionOptions() {
      return [
        { value: 'CEO', label: 'CEO' },
        { value: 'CFO', label: 'CFO' },
        { value: 'COO', label: 'COO' },
        { value: 'President', label: 'President' },
        { value: 'Vice President', label: 'Vice President' },
        { value: 'DIR', label: 'Director' },
        { value: 'TR', label: 'Treasurer' },
        { value: 'SEC', label: 'Secretary' },
        { value: 'OTH', label: 'Other' }
      ];
    },
    errors() {
      return this.$validator.errors;
    }
  },
  created() {
    this.formValues = { ...this.contact };
    this.$validator.extend(validPhoneFormat.name, validPhoneFormat.obj);
    if (this.formValues.birthDate) {
      this.$set(
        this.formValues,
        'birthDate',
        this.formatDBDateToPretty(this.formValues.birthDate)
      );
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.setupFocusHandlers();
      this.focusNext(null, 'input-first-name');
    });
  },
  beforeDestroy() {
    this.cleanupFocusHandlers();
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
  },
  methods: {
    getData() {
      const data = { ...this.formValues };
      if (data.birthDate) data.birthDate = this.formatPrettyToDBDate(data.birthDate);
      return {
        identity: {
          type: 'government-public-corp',
          contacts: [data]
        }
      };
    },
    handleInput() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.emitUpdate();
      }, 300);
    },
    handleBlur() {
      this.$emit('onInputBlur');
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('update', this.getData());
    },
    getError(fieldName, fieldLabel) {
      const error = this.errors.first(fieldName);
      if (!error) return null;

      if (error.includes(fieldLabel)) return error;

      if (error.includes('is required')) {
        return `${fieldLabel} is required`;
      }

      return `${fieldLabel} ${error}`;
    },
    async handleSubmit(event) {
      event.preventDefault();
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        this.$emit('onSubmit', this.getData());
      }
    },
    async focusNext(currentRef, nextRef) {
      const currentInput = this.$refs[currentRef];
      if (currentInput && currentInput.$el) {
        const inputElement = currentInput.$el.querySelector('input') || currentInput.$el;
        const inputName = inputElement.getAttribute('name');
        if (inputName) {
          const isValid = await this.$validator.validate(inputName);
          if (!isValid) {
            return;
          }
        }
      }
      this.$nextTick(() => {
        const refElement = this.$refs[nextRef];
        if (!refElement) {
          return;
        }

        if (refElement.$options && refElement.$options.name === 'BaseDatePicker') {
          refElement.focusDatePickerInput();
          return;
        }
        const targetElement = refElement.$el || refElement;
        const nextFocusableElement = this.findNextFocusableElement(targetElement);
        if (nextFocusableElement) {
          nextFocusableElement.focus();
        }
      });
    },
    findNextFocusableElement(element) {
      const findFocusable = el => {
        while (el) {
          if (
            ['INPUT', 'SELECT', 'BUTTON'].includes(el.tagName) &&
            !el.disabled &&
            !el.classList.contains('is-disabled')
          ) {
            return el;
          }
          if (el.children && el.children.length > 0) {
            const foundElement = findFocusable(el.children[0]);
            if (foundElement) return foundElement;
          }
          el = el.nextElementSibling;
        }
        return null;
      };

      return findFocusable(element);
    },
    submitForm() {
      this.$emit('onSubmit', this.getData());
    }
  }
};
</script>

<style scoped>
.form-content {
  max-height: calc(100vh - 380px);
}
</style>
