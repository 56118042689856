<template>
  <div
    class="form-group main-div"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
  >
    <slot name="label">
      <label v-if="label" class="input-label d-flex" :style="labelStyle">
        <i v-if="required" class="required-field float-left" />
        <span class="text-nowrap">{{ label }}</span>
        <div class="position-relative" v-if="tooltip">
          <i class="fa fa-question-circle tooltip-icon"></i>
          <span class="tooltip-text">{{ tooltip }}</span>
        </div>
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon || $slots.addonRight
      }"
    >
      <div v-if="addonLeftIcon || $slots.addonLeft">
        <slot name="addonLeft">
          <span v-if="addonLeftIcon" class="input-group-prepend">
            <div
              class="input-group-text"
              :class="{
                'error-brdr': hasError,
                'success-brdr': !hasError && touched
              }"
            >
              <i :class="addonLeftIcon"></i>
            </div>
          </span>
        </slot>
      </div>
      <slot>
        <el-select
          ref="select"
          v-focus="focus"
          class="select-primary"
          filterable
          :remote="isRemoteSearch"
          :remote-method="handleRemoteSearch"
          :loading="loading"
          :name="name"
          :value="internalValue"
          :placeholder="placeholder"
          :class="{
            'error-brdr': hasValidation && hasError,
            'success-brdr': hasValidation && !hasError && touched
          }"
          :disabled="disabled"
          :popper-class="popperClass"
          :filter-method="!isRemoteSearch ? filterMethod : undefined"
          :multiple="multiple"
          :allow-create="allowCreate"
          :collapse-tags="collapseTags"
          :value-key="isRemoteSearch ? 'label' : undefined"
          v-on="listeners"
          @keydown.native="handleKeydown"
          :id="id || 'base-select'"
        >
          <template #empty>
            <p v-if="loading" class="loading-suggestions">
              <i class="el-icon-loading"></i>
              <span>Loading suggestions...</span>
            </p>
            <p v-else class="no-suggestions">No suggestions</p>
          </template>
          <el-option
            v-for="(option, index) in currentOptions"
            class="select-primary"
            :key="option.key || option.value + index"
            :label="option.label"
            :value="isRemoteSearch ? option : option.value"
            :disabled="option.disabled"
            :class="option.class"
            :id="option.key"
          >
            <template v-if="option.class === 'loading-spinner-option'">
              <div class="loading-spinner-content">
                <i class="el-icon-loading"></i>
                <span>Loading suggestions...</span>
              </div>
            </template>
            <template v-else>
              {{ option.label }}
            </template>
          </el-option>
        </el-select>
      </slot>
      <div v-if="addonRightIcon || $slots.addonRight || hasValidation">
        <slot name="addonRight">
          <span
            class="success-icon"
            v-if="hasValidation && !hasError && touched"
          >
            <i class="fa fa-check-circle" aria-hidden="true"></i>
          </span>
          <span class="error-icon" v-else-if="hasError">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </span>
          <span v-if="addonRightIcon" class="input-group-append">
            <div
              class="input-group-text"
              :class="{
                'error-brdr': hasError,
                'success-brdr': !hasError && touched
              }"
            >
              <i :class="addonRightIcon"></i>
            </div>
          </span>
        </slot>
      </div>
    </div>
    <slot name="error" v-if="hasError || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import { Option as ElOption, Select as ElSelect } from 'element-ui';

export default {
  inheritAttrs: false,
  components: {
    ElSelect,
    ElOption
  },
  name: 'BaseSelect',
  props: {
    labelStyle: String,
    options: Array,
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: null },
    value: { type: [String, Number, Array] },
    addonLeftIcon: { type: String, description: 'Input icon on the left' },
    hasValidation: { type: Boolean, default: false },
    placeholder: { type: String, default: ' ' },
    disabled: Boolean,
    filterMethod: Function,
    multiple: Boolean,
    allowCreate: Boolean,
    collapseTags: Boolean,
    popperClass: String,
    name: String,
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    tooltip: {
      type: String,
      default: ''
    },
    id: { type: String },
    // New props for remote search
    isRemoteSearch: {
      type: Boolean,
      default: false
    },
    remoteMethod: {
      type: Function,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    useInputTextFallback: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false,
      searchTimer: null,
      remoteOptions: [],
      internalValue: this.value
    };
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    }
  },
  computed: {
    hasError() {
      return !!this.error && this.error.trim().length > 0;
    },
    hasIcon() {
      const { addonLeft, addonRight } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined ||
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    },
    currentOptions() {
      if (this.isRemoteSearch) {
        // If we have input text and useInputTextFallback, add it as first option
        if (this.useInputTextFallback && this.$refs.select?.query) {
          const options = [
            {
              label: `↳ ${this.$refs.select.query}`,
              value: this.$refs.select.query,
              class: 'current-input-option',
              key: 'address-option-input'
            }
          ];

          // Only add separator and loading state if we're actively searching
          if (this.loading) {
            options.push(
              {
                label: 'searching...',
                value: '',
                disabled: true,
                class: 'loading-state'
              }
            );
          } else if (this.remoteOptions.length > 0) {
            // Only show separator if we have suggestions
            options.push(
              ...this.remoteOptions
            );
          }

          return options;
        }
        return this.remoteOptions;
      }
      return this.options;
    }
  },
  methods: {
    onInput(value) {
      if (this.useInputTextFallback) {
        const inputText = this.$refs.select?.query || value;
        this.$emit('input', inputText);
        this.$nextTick(() => {
          if (this.$refs.select) {
            this.$refs.select.query = inputText;
          }
        });
        return;
      }
      this.$emit('input', value);
    },
    onFocus() {
      this.focused = true;
      this.$emit('focus');
    },
    setFallbackValue() {
      if (this.useInputTextFallback && this.$refs.select?.query) {
        const fallbackValue = this.$refs.select.query;
        this.$emit('input', fallbackValue);
        return true;
      }
      return false;
    },
    handleFallback() {
      return this.setFallbackValue();
    },
    onBlur() {
      this.focused = false;
      this.touched = true;
      if (this.useInputTextFallback) {
        // Always use the current query text on blur when useInputTextFallback is true
        const inputText = this.$refs.select?.query || this.value;
        this.$emit('input', inputText);
      }
      this.$emit('blur');
    },
    blur() {
      if (this.$refs.select) {
        this.$refs.select.blur();
      }
    },
    reset() {
      this.touched = false;
      this.remoteOptions = [];
    },
    setFocus() {
      if (this.$refs.select) {
        this.$refs.select.focus();
      }
    },
    handleKeydown(event) {
      if (event.key !== 'Enter' && event.key !== 'Tab') {
        return;
      }

      if (this.useInputTextFallback) {
        // Always use the current query text when useInputTextFallback is true
        const inputText = this.$refs.select?.query || this.value;
        this.$emit('input', inputText);
      } else if (this.currentOptions?.find(option => option.value === this.value)) {
        this.$emit('input', this.value);
      }

      this.blur();
      this.$emit('switch-focus');
      event.preventDefault();
    },
    // New method for remote search
    async handleRemoteSearch(query) {
      if (!this.isRemoteSearch || !this.remoteMethod) return;

      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }

      if (!query || query.length < 3) {
        this.remoteOptions = [];
        return;
      }

      this.searchTimer = setTimeout(async () => {
        try {
          const results = await this.remoteMethod(query);
          this.remoteOptions = results;
        } catch (error) {
          console.error('Remote search failed:', error);
          this.remoteOptions = [];
        }
      }, 300);
    },
    handleVisibleChange(visible) {
      // Reset keyboard navigation state when dropdown opens
      if (visible && this.isRemoteSearch) {
        this.$refs.select.query = '';
      }
    }
  },
  directives: {
    focus: {
      inserted(el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.children[0].children[0].focus();
        });
      }
    }
  },
  mounted() {
    const input = this.$el.querySelector('input');
    if (input) {
      input.classList.add('form-control');
    }
  }
};
</script>

<style scoped lang="scss">
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
.success-icon {
  position: absolute;
  top: 16px;
  right: 10px;
  font-size: 10px;
}
.error-icon {
  position: absolute;
  top: 9px;
  right: 9px;
  line-height: 1.5;
}
.main-div div {
  width: 100%;
}
.no-caret .select-primary i {
  display: none;
}

.input-group-prepend,
.input-group-append {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.input-group-prepend {
  left: 0;
}

.input-group-append {
  right: 0;
}

.input-group-text {
  background-color: transparent;
  border: none;
}

.tooltip-icon {
  margin-left: 5px;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  width: 350px;
  background-color: #322f35;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000; // Ensure it's above other elements
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-icon:hover + .tooltip-text {
  visibility: visible;
  opacity: 1;
}

input.deep {
  background: rgba(96, 127, 127, 0.07) !important;
  border-color: rgb(103, 103, 103) !important;
  border-width: 1px !important;
}

// Add styles for remote search options
::v-deep .el-select-dropdown__item {
  white-space: normal;
  word-break: break-word;
  line-height: 1.4;
  padding: 8px 12px;

  &.selected {
    font-weight: bold;
  }
}

.loading-suggestions {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 0.9em;
  padding: 8px;
  margin: 0;

  i {
    margin-right: 8px;
  }
}

.no-suggestions {
  text-align: center;
  color: #999;
  font-size: 0.9em;
  padding: 8px;
  margin: 0;
}

.current-input-option {
  color: #666;
  background: #fafafa !important;
  padding-left: 16px !important;

  &:hover, &.hover {
    color: #606266;
    background: #f0f2f5 !important;
  }

  &.selected {
    color: #606266;
    font-weight: 500;
  }
}

.loading-state {
  padding: 8px 12px !important;
  color: #999;
  font-size: 0.85em;
  text-align: center;
  cursor: default;
  user-select: none;
  background: transparent !important;
  font-style: italic;

  &:hover {
    background: transparent !important;
    cursor: default;
  }
}

.el-select-dropdown__item {
  &.current-input-option {
    &.selected, &:hover {
      color: #606266;
    }
  }
}

.loading-spinner-option {
  height: 32px !important;
  line-height: 32px !important;

  &:hover {
    background: transparent !important;
    cursor: default !important;
  }
}

.loading-spinner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 0.9em;

  i {
    margin-right: 8px;
  }
}
</style>